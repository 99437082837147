@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Sk-Modernist';
    src: url(../fonts/Sk-Modernist-Regular.otf);
    font-weight: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Sk-Modernist';
    src: url(../fonts/Sk-Modernist-Bold.otf);
    font-weight: bold;
    font-display: swap;
  }

  @font-face {
    font-family: 'cryptofont';
    src:  url('../fonts/cryptofont.eot?xavk16');
    src:  url('../fonts/cryptofont.eot?xavk16#iefix') format('embedded-opentype'),
      url('../fonts/cryptofont.ttf?xavk16') format('truetype'),
      url('../fonts/cryptofont.woff?xavk16') format('woff'),
      url('../fonts/cryptofont.svg?xavk16#cryptofont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'cryptofont';
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .cf-xtz:before {
    content: "\ed9f";
  }

  a {
    @apply transition-colors;
  }

  svg {
    @apply h-full;
  }

  #__next {
    @apply min-h-screen;
    @apply dark:bg-gray-800;
    @apply dark:text-white;
  }

  body{
    @apply bg-white;
    @apply dark:bg-gray-800;
  }
}
